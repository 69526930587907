import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getHeaders } from "../../helper/getHeaders";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomeTable } from "../CustomeTable/customeTable";
import { BASE_URL } from "../../../config/config";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        "& .MuiDataGrid-row": {
          "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        },
      },
      "& .MuiDataGrid-main": {
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#ffd230 !important",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        backgroundColor: "#ffd230 !important",
      },
    },
  })
);

const Applications = () => {
  const theme = useTheme();
  const classes = useStyles();
  const colors = tokens(theme.palette.mode);
  const [applicationData, setApplicationData] = useState([]);
  const [customeData, setCustomeData] = useState([]);
  const navigate = useNavigate();
  const [fitSize, setFitSize] = useState(false);
  const [totalApp,setTotalApp] = useState()

  const column = [
    { field: "id", headerName: "ID" },
    {
      field: "applicantName",
      headerName: "Applicant Name",
    },
    {
      field: "companyName",
      headerName: "Company Name",
    },
    {
      field: "fundRaise",
      headerName: "Fund Raise",
    },
    {
      field: "status",
      headerName: "Status",
    },
  ];

  const fetchData = async (query) => {
    await axios
      .get(`${BASE_URL}/v1/user/getApplicationDetails?skip=${query?.skip}&pageSize=${query?.pageSize}`, getHeaders())
      .then((response) => {
        console.log(response.data);
        if (response?.data.success) {
          setApplicationData(response.data.data);
          const data = response.data.data.map((item, index) => {
            return {
              id: index + 1,
              applicantName: item?.personalDetails?.firstName,
              companyName: item?.startupDetails?.companyName,
              fundRaise: item?.fundRaiseDetails?.amountToRaise,
              status: item?.applicationStatus,
              appId: item?._id,
            };
          });
          setTotalApp(response?.data?.total)
          setCustomeData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterDataApi = async (body) => {
    await axios
      .post(
        `${BASE_URL}/v1/user/filterApplications`,
        {
          [body?.field]:body?.value
        },
        getHeaders()
      )
      .then((response) => {
        if (response?.data.success) {
          setApplicationData(response.data.data);
          const data = response.data.data.map((item, index) => {
            return {
              id: index + 1,
              applicantName: item?.personalDetails?.firstName,
              companyName: item?.startupDetails?.companyName,
              fundRaise: item?.fundRaiseDetails?.amountToRaise,
              status: item?.applicationStatus,
              appId: item?._id,
            };
          });

          setCustomeData(data);
        }else{
          setCustomeData([])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchData({skip:0,pageSize:10});
  }, []);

  const filterField = [
    {
      key: "personalDetails.firstName",
      value: "Applicant Name",
    },
    {
      key: "startupDetails.companyName",
      value: "Company Name",
    },
    {
      key: "fundRaiseDetails.amountToRaise",
      value: "fundRaise",
    },
    // {
    //   key: "submitDate",
    //   value: "Submit Date",
    // },
    {
      key: "applicationStatus",
      inputType:"dropdown",
      value: "Status",
    },
  ]

  const headerStyle = {
    "& .css-rz5cp9 .MuiDataGrid-columnHeaders": {
      background: "blue",
      color: "white",
    },
    background: "black",
    color: "white",
  };

  const footerStyle = {
    background: "green", // Change this to the desired color for the footer
    color: "white", // Change this to the desired text color for the footer
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Sidebar fitSize={setFitSize} />
        <Grid xs={9} md={fitSize ? 11 : 9}>
          <Box m="20px">
            <Header title="Applications" subtitle="Managing the applications" />
            <Box
              m="10px 0 0 0"
              height="80vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              {/* <DataGrid
                rows={customeData}
                columns={columns}
                headerStyle={headerStyle}
                className={classes.root}
                style={{ background: "yellow" }}
                components={{
                  columnHeader: (params) => (
                    <div {...params} style={headerStyle}>
                      {params.label}
                    </div>
                  ),
                }}
              /> */}
              <CustomeTable
                data={customeData}
                columns={column}
                navigate={navigate}
                filterField={filterField}
                fetchData= {fetchData}
                apifuncation = {filterDataApi}
                total={totalApp}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Applications;
