import "./userLogin.css";
import { FaLinkedinIn } from "react-icons/fa6";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../../helper/getHeaders";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Admin/Loader/loader.js";
import GoogleIcon from "@mui/icons-material/Google";
import { Button, Grid } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { TypeAnimation } from "react-type-animation";
import { BASE_URL } from "../../../config/config.js";
import { getReactGa } from "../../helper/reactGa.jsx";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W6NT2KBT'
}
 
TagManager.initialize(tagManagerArgs)

ReactGA.initialize("G-E7HK2T1D8X");

const UserLogin = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const handleOnClick = async () => {
    setLoader(true);
    window.open(`${BASE_URL}/auth/google`, "_self");
  };

  return (
    <>
      <div className="bodyStyle">
        <div id="container">
          <div className="top">
            <img
              src="https://sunday-venture.s3.ap-south-1.amazonaws.com/images1701502958920.png"
              alt="logo"
              className="image"
              style={{ margin: "-20px 20%", marginTop: "50px !important" }}
            />
          </div>
          <div style={{ lineHeight: 1.5, color: "black" }}>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                `
                enabling early-stage founders \n with fund raise, tech and PMF!`,
                1000,
                `
                 enabling early-stage founders 
                with fund raise, tech and PMF!
                `,
                1000,
                // "with fund raise, tech and PMF",
                // 1000,
              ]}
              // wrapper="span"
              // speed={50}
              style={{
                whiteSpace: "pre-line",
                height: "115px",
                display: "block",
              }}
              repeat={Infinity}
            />
          </div>
          <div className="top">
            <img
              src="https://cdn.pixabay.com/animation/2022/07/31/06/27/06-27-17-124_512.gif"
              alt="logo"
              className="image"
              style={{ margin: "0px 10%", height: "65%" }}
            />
          </div>
          <form style={{ marginTop: "20px" }}>
            {loader ? (
              <Loader />
            ) : (
              <Button
                name="login"
                type="button"
                style={{
                  textTransform: "capitalize",
                  color: "black",
                  backgroundColor: "#fdd230",
                  alignItems: "flex-end",
                }}
                onClick={handleOnClick}
              >
                <GoogleIcon />{" "}
                <span style={{ marginLeft: "4px" }}> Continue with Google</span>
              </Button>
            )}
            {/* <Grid> */}
            <span style={{ marginTop: "50px", fontSize: "12px" }}>
              <span style={{ fontSize: "16px" }}>©</span> Sunday Labs 2023 -
              2024
            </span>
            {/* </Grid> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
