import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Filters = ({ filter, setFilter, field, apifuncation }) => {
  const handleChangeField = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setFilter({
      field: value,
      value: "",
    });
  };

  const handleChangeValue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setFilter({
      ...filter,
      value: value,
    });
    console.log(filter);
  };
  const handleOnClick = () => {
    apifuncation(filter);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
      <Grid item>
        <FormControl sx={{ textAlign: "left", width: "200px !important" }}>
          <InputLabel id="status-label">Select Field</InputLabel>
          <Select
            label="Select Field"
            variant="outlined"
            // value={status}
            onChange={handleChangeField}
          >
            {field?.map((it) => {
              return <MenuItem value={it.key}>{it.value}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        {filter?.field == "applicationStatus" ? (
          <FormControl sx={{ textAlign: "left", width: "200px !important" }}>
            <InputLabel id="status-label">Search</InputLabel>
            <Select
              label="Status"
              variant="outlined"
              //   value={}
              onChange={handleChangeValue}
            >
              <MenuItem value="inprogress">Inprogress</MenuItem>
              <MenuItem value="in_review">In Review</MenuItem>
              <MenuItem value="applied">Applied</MenuItem>
              <MenuItem value="not_apply">Not Apply</MenuItem>
              <MenuItem value="in_dd">In DD</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="shortlisted">Shortlisted</MenuItem>
              <MenuItem value="shortlisted_for_telecall">
                Shortlisted for telecall
              </MenuItem>
              <MenuItem value="selected">Selected</MenuItem>
            </Select>
          </FormControl>
        ) : filter?.field == "cohortStatus" ? (
          <FormControl sx={{ textAlign: "left", width: "200px !important" }}>
            <InputLabel id="status-label">Search</InputLabel>
            <Select
              label="Status"
              variant="outlined"
              //   value={}
              onChange={handleChangeValue}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="dropped">Dropped</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <TextField
            // label="Search"
            variant="outlined"
            type={
              (filter?.field == "cohortEndDate" ||
                filter?.field == "cohortApplicationStartDate" ||
                filter?.field == "cohortApplicationEndDate" ||
                filter?.field == "cohortStartDate") &&
              filter?.field
                ? "date"
                : "text"
            }
            value={filter?.value}
            placeholder="Search"
            onChange={handleChangeValue}
          />
        )}
      </Grid>
      <Grid item>
        {filter?.field && filter?.value ? (
          <Button
            variant="outlined"
            startIcon={<SearchIcon />}
            style={{
              color: "black",
              borderColor: "black",
              padding: "14px",
            }}
            onClick={handleOnClick}
          >
            Search
          </Button>
        ) : (
          <Button
            variant="outlined"
            startIcon={<SearchIcon />}
            style={{
              color: "grey",
              borderColor: "grey",
              padding: "14px",
              cursor: "not-allowed !important",
            }}
          >
            Search
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Filters;
