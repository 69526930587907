import * as yup from "yup";
import "../Cohort/form.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { getHeaders } from "../../helper/getHeaders";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Header/header";
import { BASE_URL } from "../../../config/config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Chip, List, ListItem, ListItemText } from "@mui/material";
import moment from "moment";

const ApplicationForm = (props) => {
  const [applicationData, setApplicationData] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [statusForUpdate, setStatusForUpdate] = useState(null);
  const applicationId = queryParams.get("applicationId");
  const [fitSize, setFitSize] = useState();
  const navigate = useNavigate();
  const fetchData = async () => {
    await axios
      .get(
        `${BASE_URL}/v1/user/getApplicationDetails?applicationId=${applicationId}`
      )
      .then((response) => {
        console.log(response.data);
        if (response?.data.success) {
          setApplicationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setStatusForUpdate(value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleOnClick = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/user/updateApplicationStatus`,
        {
          applicationId,
          status: statusForUpdate,
        }
        // getHeaders()
      );

      if (response.data.success) {
        navigate(-1);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar fitSize={setFitSize} />
      <main
        class="demo-page-content"
        style={{ marginTop: "20px", marginLeft: "40px" }}
      >
        <Header />
        <section style={{ width: "135%" }}>
          <div class="href-target" id="input-types"></div>
          <h1>{props.header || "Application Update"}</h1>
          <div class="nice-form-group">
            <label style={{ fontSize: "16px", fontWeight: "bold" }}>
              Applicant Details{" "}
              <span>
                <Chip
                  style={{ minWidth: 100 }}
                  label={moment(applicationData?.createdAt).format(
                    "YYYY-MM-DD"
                  )}
                  variant="outlined"
                />
              </span>
            </label>
            <div
              style={{
                padding: "0px 5px 0px 5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="nice-form-group">
                <label>First Name</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.personalDetails?.firstName}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.personalDetails?.firstName}
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>Last Name</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.personalDetails?.lastName}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.personalDetails?.lastName}
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>DOB</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.personalDetails?.dateOfBirth}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.personalDetails?.dateOfBirth}
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>Email</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.personalDetails?.dateOfBirth}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.userEmail}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div class="nice-form-group">
            <label style={{ fontSize: "16px", fontWeight: "bold" }}>
              Startup Details
            </label>
            <div
              style={{
                padding: "0px 5px 0px 5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="nice-form-group">
                <label>Company Name</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.startupDetails?.companyName}
                /> */}
                <div>
                  <Chip
                    style={{ minWidth: 100 }}
                    label={applicationData?.startupDetails?.companyName}
                    variant="outlined"
                  />
                </div>
              </div>
              <div class="nice-form-group">
                <label>Company Domain</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.startupDetails?.companyDomain}
                /> */}
                <div>
                  <Chip
                    style={{ minWidth: 100 }}
                    label={applicationData?.startupDetails?.companyDomain}
                    variant="outlined"
                  />
                </div>
              </div>
              <div class="nice-form-group">
                <label>Startup Stage</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.startupDetails?.startUpStage}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.startupDetails?.startUpStage}
                  variant="outlined"
                />
              </div>
            </div>
            <div class="nice-form-group">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="nice-form-group">
                  <label>Have you raised funds yet?</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds?.fundsAmount
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails
                        ?.raisedFundCheckbox
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>What was the last valuation? (in USD)</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds?.fundsAmount
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails?.raisedFunds
                        ?.lastValuation
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>Who led the round?</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds?.latestRounds
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails?.raisedFunds
                        ?.whoLedTheRound
                    }
                    variant="outlined"
                  />
                </div>
                {/* <div class="nice-form-group">
                  <label>Share demo & login details, etc</label>
                  <Box sx={{ width: 200 }}>
                  <Chip
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                    }}
                    style={{padding:"10px",minWidth:100}}
                    label={applicationData?.fundRaiseDetails?.reasonToUse}
                    variant="outlined"
                  />
                </Box>
                </div> */}
              </div>
            </div>
            <div class="nice-form-group">
              <label style={{ fontSize: "16px", fontWeight: "bold" }}>
                Raised funds
              </label>
              <div
                style={{
                  padding: "0px 20px 0px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="nice-form-group">
                  <label>
                    How much have you raised <br></br>so far? (in USD)
                  </label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds?.fundsAmount
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails?.raisedFunds?.fundsAmount
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>What was the latest round ?</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds?.latestRounds
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails?.raisedFunds?.latestRounds
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>
                    how long you have been working<br></br> on your startup? (in
                    months)
                  </label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={
                      applicationData?.startupDetails?.raisedFunds
                        ?.lastValuation
                    }
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      applicationData?.startupDetails?.raisedFunds
                        ?.lastValuation
                    }
                    variant="outlined"
                  />
                </div>
              </div>
              <div
                style={{
                  padding: "0px 20px 0px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div class="nice-form-group">
                  <label>Website Link</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={applicationData?.startupDetails?.websiteLink}
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      <a
                        href={
                          "https://" +
                          applicationData?.startupDetails?.websiteLink?.split(
                            "https://"
                          )[0]
                        }
                        target="blank"
                      >
                        {applicationData?.startupDetails?.websiteLink}
                      </a>
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>App Store Link</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={applicationData?.startupDetails?.appStoreLink}
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      <a
                        href={
                          "https://" +
                          applicationData?.startupDetails?.appStoreLink?.split(
                            "https://"
                          )[0]
                        }
                        target="blank"
                      >
                        {applicationData?.startupDetails?.appStoreLink}
                      </a>
                    }
                    variant="outlined"
                  />
                </div>
                <div class="nice-form-group">
                  <label>Play Store Link</label>
                  {/* <input
                    type="text"
                    // placeholder="Enter Cohort Name"
                    value={applicationData?.startupDetails?.playStoreLink}
                  /> */}
                  <Chip
                    style={{ minWidth: 100 }}
                    label={
                      <a
                        href={
                          "https://" +
                          applicationData?.startupDetails?.playStoreLink?.split(
                            "https://"
                          )[0]
                        }
                        target="blank"
                      >
                        {applicationData?.startupDetails?.playStoreLink}
                      </a>
                    }
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="nice-form-group">
            <label style={{ fontSize: "16px", fontWeight: "bold" }}>
              Fund Raise Details
            </label>
            <div
              style={{
                padding: "0px 5px 0px 5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="nice-form-group">
                <label>How much are you looking to raise?</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.fundRaiseDetails?.amountToRaise}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={applicationData?.fundRaiseDetails?.amountToRaise}
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>What will you use it for?</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.fundRaiseDetails?.reasonToUse}
                /> */}
                <Box sx={{ width: 200 }}>
                  <Chip
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                    }}
                    style={{ padding: "10px", minWidth: 100 }}
                    label={applicationData?.fundRaiseDetails?.reasonToUse}
                    variant="outlined"
                  />
                </Box>
              </div>
              <div class="nice-form-group">
                <label>Pitch Deck</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.fundRaiseDetails?.pitchDeck}
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={
                    <a
                      href={applicationData?.fundRaiseDetails?.pitchDeck}
                      target="blank"
                    >
                      {applicationData?.fundRaiseDetails?.pitchDeck
                        ? "Click here!"
                        : ""}
                    </a>
                  }
                  variant="outlined"
                />
              </div>
            </div>
            <div
              style={{
                padding: "0px 5px 0px 5px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div class="nice-form-group">
                <label>
                  {" "}
                  How much more can you invest <br></br> personally if
                  fundraising fails?{" "}
                </label>
                {/* <input
                  type="text"
                  // placeholder=""
                  value={
                    applicationData?.fundRaiseDetails?.amountInvestPersonally
                  }
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={
                    applicationData?.fundRaiseDetails?.amountInvestPersonally
                  }
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>
                  Will you still continue to <br></br>run if you dont raise
                  funds?
                </label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={
                    applicationData?.fundRaiseDetails?.continueWithoutFunds
                  }
                /> */}
                <Chip
                  style={{ minWidth: 100 }}
                  label={
                    applicationData?.fundRaiseDetails?.continueWithoutFunds
                  }
                  variant="outlined"
                />
              </div>
              <div class="nice-form-group">
                <label>If yes, how will you survive?</label>
                {/* <input
                  type="text"
                  // placeholder="Enter Cohort Name"
                  value={applicationData?.fundRaiseDetails?.reasonToSurvive}
                /> */}
                <Box sx={{ width: 200 }}>
                  <Chip
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                    }}
                    style={{ padding: "10px", minWidth: 100 }}
                    label={applicationData?.fundRaiseDetails?.reasonToSurvive}
                    variant="outlined"
                  />
                </Box>
              </div>
            </div>
          </div>

          {/* founders details */}
          <div class="nice-form-group" style={{ width: "100%" }}>
            <label style={{ fontSize: "16px", fontWeight: "bold" }}>
              Founders details
            </label>
            <p>
              {applicationData?.foundersDetails?.founders?.length == 0
                ? "No founders Details!"
                : ""}
            </p>
            {applicationData?.foundersDetails?.founders?.map((item, index) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Founder {index + 1}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ListItem key={index}>
                      <ListItemText
                        primary={
                          <>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.firstName ? "First Name" : ""} :{" "}
                              {item.firstName}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.lastName ? "Last Name" : ""} :{" "}
                              {item.lastName}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.email ? "email" : ""} :{" "}
                              <span
                                style={{
                                  color: item.isEmailVerified
                                    ? "green"
                                    : "grey",
                                }}
                              >
                                {item.email}
                              </span>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.phoneNumber ? "Phone Number" : ""} :{" "}
                              <span
                                style={{
                                  color: item.isPhoneNumberVerified
                                    ? "green"
                                    : "grey",
                                }}
                              >
                                {item.phoneNumber}
                              </span>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.linkdinProfile ? "Linkdin Profile" : ""} :{" "}
                              <a href={item.linkdinProfile} target="blank">
                                Click here!
                              </a>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.domain ? "Domain" : ""} : {item.domain}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.lastEmployer ? "Last Employer" : ""} :{" "}
                              {item.lastEmployer}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.lastSchoolOrCollege
                                ? "Last school or college name"
                                : ""}{" "}
                              : {item.lastSchoolOrCollege}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.percentageStake ? "Percentage Stake" : ""} :{" "}
                              {item.percentageStake}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.salaryDrawn ? "Salary Drawn" : ""} :{" "}
                              {item.salaryDrawn}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.fullTimeOrPartTime
                                ? "Full time or part time"
                                : ""}{" "}
                              : {item.fullTimeOrPartTime}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                            >
                              {item.amountInvested ? "Amount Invested" : ""} :{" "}
                              {item.amountInvested}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
          <div class="nice-form-group">
            <label>Status</label>
            <select onChange={handleOnChange}>
              <option
                selected={
                  applicationData?.applicationStatus == "inprogress"
                    ? true
                    : false
                }
                value={"inprogress"}
              >
                Application In Progress
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "applied" ? true : false
                }
                value={"applied"}
              >
                Application Submitted
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "in_review"
                    ? true
                    : false
                }
                value={"in_review"}
              >
                Application In Review
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "in_dd" ? true : false
                }
                value={"in_dd"}
              >
                DD In Process
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "shortlisted"
                    ? true
                    : false
                }
                value={"shortlisted"}
              >
                Shortlisted For Assessment
              </option>
              <option
                selected={
                  applicationData?.applicationStatus ==
                  "shortlisted_for_telecall"
                    ? true
                    : false
                }
                value={"shortlisted_for_telecall"}
              >
                Shortlisted For Tele Call With Investors
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "rejected"
                    ? true
                    : false
                }
                value={"rejected"}
              >
                Rejected
              </option>
              <option
                selected={
                  applicationData?.applicationStatus == "selected"
                    ? true
                    : false
                }
                value={"selected"}
              >
                Selected
              </option>
            </select>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <details onClick={handleOnClick}>
              <summary>
                <div class="toggle-code">Update</div>
              </summary>
              <script src="https://gist.github.com/nielsVoogt/e25c9c8f2b8456bbd1239b775d21333f.js"></script>
            </details>

            <details onClick={() => navigate(-1)}>
              <summary>
                <div class="toggle-code">cancel</div>
              </summary>
              <script src="https://gist.github.com/nielsVoogt/e25c9c8f2b8456bbd1239b775d21333f.js"></script>
            </details>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ApplicationForm;
