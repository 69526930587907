import ReactGA from "react-ga4";

ReactGA.initialize("G-E7HK2T1D8X");

export const getReactGa = (
  category,
  action,
) => {
  ReactGA.event({
    category,
    action,
  });

  return {
    success : true
  };
};
