import "./header.css";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config/config";

export const Header = () => {
  const navigate = useNavigate();
  const innerWidth = window.innerWidth;
  const logoHeight = innerWidth < 568 ? 200 : 150

  return (
    <>
      <Grid sm="12" xs="12">
        <div claasName="logo-header">
          <div
            className="logo-img"
            style={{
              display: "flex",
              justifyContent:
                innerWidth > 568 ? "space-between" : "space-between",
              margin: "auto auto",
            }}
          >
            <img
              src="https://sunday-venture.s3.ap-south-1.amazonaws.com/images1701502958920.png"
              alt="logo"
              style={{
                height: logoHeight,
              }}
              height={logoHeight}
              className="image"
            />
            <div>
              {innerWidth > 568 ? (
                <Button
                  // variant="outlined"
                  color="primary"
                  onClick={() =>
                    window.open(`${BASE_URL}/logout`, "_self")
                  }
                  style={{
                    backgroundColor: "transparent",
                    textTransform: "capitalize",
                    color: "black",
                    margin: "20px 50px",
                    width: "3%",
                    // border:"none",
                    fontSize: "14px",
                  }}
                  // sm="2px"
                >
                  {/* <ArrowBackIcon />
                   */}
                  Logout
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};
