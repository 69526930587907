import React, { useState, useEffect } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { isEmpty } from "lodash";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Container,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import BasicModal from "./model";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StepIcon from "@mui/material/StepIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Header } from "../Header/header";
import Avatars from "./avatar";
import Model from "./model";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import {getHeaders} from '../../Helper/getHeaders'
import { BASE_URL } from "../../../config/config";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W6NT2KBT'
}
 
TagManager.initialize(tagManagerArgs)


ReactGA.initialize("G-E7HK2T1D8X");

const steps = [
  "Personal Details",
  "Startup Details",
  "Founders Details",
  "Fund Raise Details",
];

let highlightEmpty, setHighlightEmpty;
const MultiStepForm = ({ user }) => {
  [highlightEmpty, setHighlightEmpty] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cohortId = queryParams.get("cohortId");
  const userId = localStorage.getItem("userid");
  const [applicaionData, setApplicationData] = useState({});
  const [funderObj, setFunderObj] = useState({
    firstName: "",
    lastName: "",
    linkdinProfile: "",
    email: "",
    phoneNumber: "",
    domain: "",
    lastEmployer: "",
    isApplying: false,
    lastSchoolOrCollege: "",
    percentageStake: "",
    salaryDrawn: "",
    fullTimeOrPartTime: "",
    amountInvested: "",
  });

  const [fundersArray, setFundersArray] = useState([]);

  const [formData, setFormData] = useState({
    demoLoginDetails: "",
    lastValuation: "",
    whoLedTheRound: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    profilePhoto: user?._json?.picture,
    companyName: "",
    companyDomain: "",
    startUpStage: "",
    raisedFundCheckbox: "",
    fundsAmount: "",
    latestRounds: "",
    startUpDuration: "",
    websiteLink: "",
    appStoreLink: "",
    playStoreLink: "",
    amountToRaise: "",
    reasonToUse: "",
    pitchDeck: "",
    amountInvestPersonally: "",
    continueWithoutFunds: "",
    reasonToSurvive: "",
    foundersNumber: "",
    whoLedTheRound: "",
    lastValuation: "",
    demoLoginDetails: "",
    applicationProgressStatus: "",
    applicationStatus: "",
  });

  const handleNext = () => {
    if (activeStep === 0) {
      const isFirstNameValid = formData.firstName?.trim() !== "";
      const isLastNameValid = formData.lastName?.trim() !== "";
      const isDobValid = formData.dateOfBirth?.trim() !== "";
      const isProfilePhotoValid = formData.profilePhoto?.trim() !== "";
      if (
        !isFirstNameValid ||
        !isLastNameValid ||
        !isDobValid ||
        !isProfilePhotoValid
      ) {
        setHighlightEmpty(true);
        return;
      }
    }

    if (activeStep === 1) {
      const isCompanyName = formData.companyName?.trim() !== "";
      const isCompanyDomain = formData.companyDomain?.trim() !== "";
      const isStartUpStage = formData.startUpStage?.trim() !== "";
      const isRaisedFundCheckbox = formData.raisedFundCheckbox?.trim() !== "";
      const isStartUpDuration = formData.startUpDuration?.trim() !== "";
      const isWebsiteLink = formData.websiteLink?.trim() !== "";
      const isAppStoreLink = formData.appStoreLink?.trim() !== "";
      const isPlayStoreLink = formData.playStoreLink?.trim() !== "";
      const isLatestRounds = formData.latestRounds?.trim() !== "";
      const isLastValuation = formData.lastValuation?.trim() !== "";
      const isWhoLedTheRound = formData.whoLedTheRound?.trim() !== "";

      if (
        (!isLatestRounds && formData.raisedFundCheckbox == "Yes") ||
        (!isLastValuation && formData.raisedFundCheckbox == "Yes") ||
        (!isWhoLedTheRound && formData.raisedFundCheckbox == "Yes") ||
        !isCompanyName ||
        // !isPlayStoreLink ||
        // !isAppStoreLink ||
        !isWebsiteLink ||
        !isStartUpDuration ||
        !isRaisedFundCheckbox ||
        !isStartUpStage ||
        !isCompanyDomain
      ) {
        setHighlightEmpty(true);
        return;
      }
    }

    if (activeStep === 2) {
      const isFounderNumber = formData.foundersNumber?.trim() !== "";
      const isFilled = fundersArray.every((item) => {
        return (
          item.firstName &&
          item.lastName &&
          item.phoneNumber &&
          item.email &&
          item.linkdinProfile &&
          item.domain &&
          item.lastEmployer &&
          item.lastSchoolOrCollege &&
          item.percentageStake &&
          item.salaryDrawn &&
          item.fullTimeOrPartTime &&
          item.amountInvested
        );
      });

      if (!isFilled || !isFounderNumber) {
        console.log("step 2 in 3", isFilled);
        setHighlightEmpty(true);
        return;
      }

      //   if (
      //   !isAmountToRaise ||
      //   !isReasonToSurvive ||
      //   !isReasonToUse ||
      //   !isPitchDeck ||
      //   !isAmountInvestPersonally ||
      //   !isContinueWithoutFunds
      // ) {
      //   setHighlightEmpty(true);
      //   return;
      // }
    }

    if (activeStep === 3) {
      const isAmountToRaise = formData.amountToRaise?.trim() !== "";
      const isReasonToUse = formData.reasonToUse?.trim() !== "";
      const isPitchDeck = formData.pitchDeck?.trim() !== "";
      const isAmountInvestPersonally =
        formData.amountInvestPersonally?.trim() !== "";
      const isContinueWithoutFunds =
        formData.continueWithoutFunds?.trim() !== "";
      const isReasonToSurvive = formData.reasonToSurvive?.trim() !== "";
      if (
        !isAmountToRaise ||
        (!isReasonToSurvive && formData?.continueWithoutFunds == "Yes") ||
        !isReasonToUse ||
        !isAmountInvestPersonally ||
        !isContinueWithoutFunds
      ) {
        setHighlightEmpty(true);
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setHighlightEmpty(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleFundersChange = (field, value) => {
    setFunderObj((prevData) => ({ ...prevData, [field]: value }));
  };

  const stepStyle = {
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#ffd230",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#ffd230",
      },
    },
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/getApplicationDetailsbyCohort?cohortId=${cohortId}&userId=${userId}`
      );

      if (!response.data.success) {
        const res = await axios.post(`${BASE_URL}/v1/user/createApplication`, {
          userId: userId,
          cohortId: cohortId,
          status: "inprogress",
          applicationProgressStatus: [
            {
              date: new Date(),
              status: "applicationInProgress",
            },
          ],
        });
        if (res.data.success) {
          setApplicationData(res.data.data);
        }
      } else {
        const appData = response.data.data;
        setApplicationData(appData);
        setFormData({
          ...appData?.personalDetails,
          ...appData?.startupDetails,
          ...appData?.startupDetails?.raisedFunds,
          ...appData?.fundRaiseDetails,
          foundersNumber: appData?.foundersDetails?.foundersNumber,
        });
        setFundersArray(
          appData?.foundersDetails?.founders?.length > 0
            ? appData?.foundersDetails?.founders
            : []
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleApplicationUpdate = async () => {
    const updateBody = {
      applicationId: applicaionData.applicationId || applicaionData._id,
      userId: userId,
      cohortId: cohortId,
      personalDetails: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        dateOfBirth: formData.dateOfBirth,
        profilePhoto: formData.profilePhoto,
      },
      startupDetails: {
        companyName: formData.companyName,
        companyDomain: formData.companyDomain,
        startUpStage: formData.startUpStage,
        raisedFundCheckbox: formData.raisedFundCheckbox,
        raisedFunds: {
          fundsAmount: formData.fundsAmount,
          latestRounds: formData.latestRounds,
          lastValuation: formData.lastValuation,
          whoLedTheRound: formData.whoLedTheRound,
        },
        startUpDuration: formData.startUpDuration,
        websiteLink: formData.websiteLink,
        appStoreLink: formData.appStoreLink,
        playStoreLink: formData.playStoreLink,
        demoLoginDetails: formData.demoLoginDetails,
      },
      foundersDetails: {
        foundersNumber: formData.foundersNumber,
        founders: fundersArray,
      },
      fundRaiseDetails: {
        amountToRaise: formData.amountToRaise,
        reasonToUse: formData.reasonToUse,
        pitchDeck: formData.pitchDeck,
        amountInvestPersonally: formData.amountInvestPersonally,
        continueWithoutFunds: formData.continueWithoutFunds,
        reasonToSurvive: formData.reasonToSurvive,
      },
      applicationStatus: activeStep == 3 ? "applied" : "inprogress",
      applicationProgressStatus: formData?.applicationProgressStatus
        ? formData?.applicationProgressStatus.push({
            time: new Date(),
            status:
              activeStep == 3
                ? "applicationSubmitted"
                : "applicationInProgress",
          })
        : [
            {
              time: new Date(),
              status:
                activeStep == 3
                  ? "applicationSubmitted"
                  : "applicationInProgress",
            },
          ],
    };
    try {
      ReactGA.event({
        category: "Application Step",
        action: activeStep || "",
      });
      if (
        activeStep == 3 &&
        !isEmpty(formData?.amountToRaise) &&
        !isEmpty(formData?.reasonToUse) &&
        !isEmpty(formData?.amountInvestPersonally)
      ) {
        if (
          formData.continueWithoutFunds == "Yes" &&
          !isEmpty(formData?.reasonToSurvive)
        ) {
          const response = await axios.put(
            `${BASE_URL}/v1/user/updateApplicationDetails`,
            updateBody,
            {
              "Content-Type": "multipart/form-data",
              accessToken: "",
            }
          );

          if (response.data.success) {
            console.log(response.data.data);
          }
        } else if (formData?.continueWithoutFunds == "No") {
          const response = await axios.put(
            `${BASE_URL}/v1/user/updateApplicationDetails`,
            updateBody,
            {
              "Content-Type": "multipart/form-data",
              accessToken: "",
            }
          );

          if (response.data.success) {
            console.log(response.data.data);
          }
        }
      }
      if (activeStep != 3) {
        const response = await axios.put(
          `${BASE_URL}/v1/user/updateApplicationDetails`,
          updateBody,
          {
            "Content-Type": "multipart/form-data",
            accessToken: "",
          }
        );

        if (response.data.success) {
          console.log(response.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/application")}
          style={{
            color: "black",
            backgroundColor: "transparent",
            marginBottom: "5%",
            border: "0px",
          }}
          sm="2px"
        >
          <ArrowBackIcon />
        </Button>
        <Stepper activeStep={activeStep} alternativeLabel sx={stepStyle}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div
          style={{
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {activeStep === steps.length ? (
            <>
              <Typography variant="h5">
                Your application is under review!
              </Typography>
              <Button
                // variant="outlined"
                color="primary"
                onClick={() => navigate("/application")}
                style={{
                  color: "black",
                  backgroundColor: "#ffd230",
                  textTransform: "capitalize",
                  marginTop: "20px",
                }}
              >
                Go To Home
              </Button>
            </>
          ) : (
            <div
              style={{
                marginTop: "20px",
              }}
            >
              {activeStep === 0 && (
                <Step1
                  firstName={formData.firstName}
                  lastName={formData.lastName}
                  dateOfBirth={formData.dateOfBirth}
                  profilePhoto={formData.profilePhoto}
                  onFieldChange={handleChange}
                />
              )}
              {activeStep === 1 && (
                <Step2
                  demoLoginDetails={formData.demoLoginDetails}
                  companyName={formData.companyName}
                  companyDomain={formData.companyDomain}
                  startUpStage={formData.startUpStage}
                  raisedFundCheckbox={formData.raisedFundCheckbox}
                  fundsAmount={formData.fundsAmount}
                  latestRounds={formData.latestRounds}
                  startUpDuration={formData.startUpDuration}
                  websiteLink={formData.websiteLink}
                  appStoreLink={formData.appStoreLink}
                  playStoreLink={formData.playStoreLink}
                  lastValuation={formData.lastValuation}
                  whoLedTheRound={formData.whoLedTheRound}
                  onFieldChange={handleChange}
                />
              )}
              {activeStep === 2 && (
                <Step3
                  firstName={funderObj.firstName}
                  lastName={funderObj.lastName}
                  linkdinProfile={funderObj.linkdinProfile}
                  email={funderObj.email}
                  phoneNumber={funderObj.phoneNumber}
                  domain={funderObj.domain}
                  lastEmployer={funderObj.lastEmployer}
                  lastSchoolOrCollege={funderObj.lastSchoolOrCollege}
                  percentageStake={funderObj.percentageStake}
                  salaryDrawn={funderObj.salaryDrawn}
                  fullTimeOrPartTime={funderObj.fullTimeOrPartTime}
                  amountInvested={funderObj.amountInvested}
                  fundersArray={fundersArray}
                  funderObj={funderObj}
                  foundersNumber={formData.foundersNumber}
                  setFunderObj={setFunderObj}
                  setFundersArray={setFundersArray}
                  onFieldChange={handleFundersChange}
                  formData={formData}
                  setFormData={setFormData}
                />
              )}
              {activeStep === 3 && (
                <Step4
                  amountToRaise={formData.amountToRaise}
                  reasonToUse={formData.reasonToUse}
                  pitchDeck={formData.pitchDeck}
                  amountInvestPersonally={formData.amountInvestPersonally}
                  continueWithoutFunds={formData.continueWithoutFunds}
                  reasonToSurvive={formData.reasonToSurvive}
                  onFieldChange={handleChange}
                />
              )}

              <div
                style={{
                  margin: "20px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  // variant="outlined"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{
                    color: "black",
                    backgroundColor: "transparent",
                    textTransform: "capitalize",
                  }}
                  // style={
                  //   activeStep === 0
                  //     ? {}
                  //     : { color: "black", borderColor: "black" }
                  // }
                >
                  Back
                </Button>
                <Button
                  // variant="outlined"
                  style={{
                    color: "black",
                    backgroundColor: "#ffd230",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    handleNext();
                    handleApplicationUpdate();
                  }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Save & Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

const Step1 = ({
  firstName,
  lastName,
  dateOfBirth,
  profilePhoto,
  onFieldChange,
}) => {
  const isFirstNameValid = firstName?.trim() !== "";
  const isLastNameValid = lastName?.trim() !== "";
  const isDobValid = dateOfBirth?.trim() !== "";
  const isProfilePhotoValid = profilePhoto?.trim() !== "";

  const [image, setImage] = useState(profilePhoto);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // profilePhoto = file
    if (file) {
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileUpload = async (e) => {
    const body = new FormData();
    body.append("file", e.target.files[0]);
    const response = await axios.post("${BASE_URL}/v1/user/upload/file", body, {
      headers: {
        "Content-Type": "application/json",
        accessToken: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(response.data);
    if (response.data.success) {
      onFieldChange("profilePhoto", response?.data?.data?.fileUrl);
      setImage(response?.data?.data?.fileUrl);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <label htmlFor="avatar-input">
            <input
              id="avatar-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <IconButton
              aria-label="upload picture"
              style={{ color: "grey" }}
              component="span"
            >
              <PhotoCamera />
            </IconButton>
          </label>
          <Avatar
            alt="User Avatar"
            src={image}
            sx={{ width: 100, height: 100 }}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="First Name"
          variant="outlined"
          value={firstName}
          onChange={(e) => onFieldChange("firstName", e.target.value)}
          fullWidth
          required
          error={!isFirstNameValid && highlightEmpty}
          helperText={!isFirstNameValid && highlightEmpty}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Last Name"
          variant="outlined"
          value={lastName}
          onChange={(e) => onFieldChange("lastName", e.target.value)}
          fullWidth
          required
          error={!isLastNameValid && highlightEmpty}
          helperText={!isLastNameValid && highlightEmpty}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Date of Birth"
          variant="outlined"
          type="date"
          value={dateOfBirth}
          onChange={(e) => onFieldChange("dateOfBirth", e.target.value)}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
          error={!isDobValid && highlightEmpty}
          helperText={!isDobValid && highlightEmpty}
        />
      </Grid>
    </Grid>
  );
};

const Step2 = ({
  companyName,
  companyDomain,
  startUpStage,
  raisedFundCheckbox,
  fundsAmount,
  latestRounds,
  startUpDuration,
  websiteLink,
  appStoreLink,
  playStoreLink,
  whoLedTheRound,
  lastValuation,
  demoLoginDetails,
  onFieldChange,
}) => {
  const companyDomains = [
    "Telecom",
    "E-Commerce",
    "IT Services",
    "Marketing, Advertising, Sales",
    "Government sector",
    "Media & Entertainment",
    "Travel & Tourism",
    "IoT & Geofencing",
    "Finance",
    `Supply Chain, Inventory 
    & Order Management`,
    "Health Care. Fitness & Recreation",
    "Social Media, Social Media Analysis",
    "Ticketing",
    "Service Sector",
    "Gaming",
    "Education",
    "Distribution Management System",
    "Science & Innovation",
    "Construction & Engineering",
    "Manufacturing",
    "Ecology and Environmental Protection",
    "Project Management",
    "Logistics",
    "Procurement Management Solution",
    "Agriculture",
  ];

  const [isFundRaise, setIsFundRaise] = useState(raisedFundCheckbox);
  const isCompanyName = companyName?.trim() !== "";
  const isCompanyDomain = companyDomain?.trim() !== "";
  const isStartUpStage = startUpStage?.trim() !== "";
  const isRaisedFundCheckbox = raisedFundCheckbox?.trim() !== "";
  const isStartUpDuration = startUpDuration?.trim() !== "";
  const isWebsiteLink = websiteLink?.trim() !== "";
  const isAppStoreLink = appStoreLink?.trim() !== "";
  const isPlayStoreLink = playStoreLink?.trim() !== "";
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Company Name"
          variant="outlined"
          value={companyName}
          onChange={(e) => onFieldChange("companyName", e.target.value)}
          fullWidth
          required
          error={!isCompanyName && highlightEmpty}
          helperText={!isCompanyName && highlightEmpty}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Company Domain *
          </InputLabel>
          <Select
            label="Company Domain"
            variant="outlined"
            value={companyDomain}
            onChange={(e) => onFieldChange("companyDomain", e.target.value)}
            fullWidth
            required
            error={!isCompanyDomain && highlightEmpty}
            helperText={!isCompanyDomain && highlightEmpty}
          >
            {companyDomains.map((stage) => (
              <MenuItem key={stage} value={stage}>
                {stage}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Startup Stage *
          </InputLabel>
          <Select
            label="Startup Stage"
            variant="outlined"
            value={startUpStage}
            onChange={(e) => onFieldChange("startUpStage", e.target.value)}
            fullWidth
            required
            error={!isStartUpStage && highlightEmpty}
            helperText={!isStartUpStage && highlightEmpty}
          >
            <MenuItem value="Idea">Idea</MenuItem>
            <MenuItem value="Started building">Started building</MenuItem>
            <MenuItem value="MVP Launched">MVP Launched</MenuItem>
            <MenuItem value="Growth">Growth</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Have you raised funds yet? *
          </InputLabel>
          <Select
            label="Have you raised funds yet?"
            variant="outlined"
            value={raisedFundCheckbox}
            onChange={(e) =>
              onFieldChange("raisedFundCheckbox", e.target.value)
            }
            fullWidth
            required
            error={!isRaisedFundCheckbox && highlightEmpty}
            helperText={!isRaisedFundCheckbox && highlightEmpty}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {raisedFundCheckbox == "Yes" ? (
        <>
          <Grid item xs={6}>
            <TextField
              label="How much have you raised so far? (in USD)"
              variant="outlined"
              value={fundsAmount}
              type="number"
              placeholder="0"
              defaultValue={0}
              onChange={(e) => onFieldChange("fundsAmount", e.target.value)}
              fullWidth
              error={!fundsAmount && highlightEmpty}
              helperText={!fundsAmount && highlightEmpty}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                What was the latest round ? *
              </InputLabel>
              <Select
                label="What was the latest round ?"
                variant="outlined"
                value={latestRounds}
                onChange={(e) => onFieldChange("latestRounds", e.target.value)}
                fullWidth
                error={
                  raisedFundCheckbox == "No" ||
                  (!latestRounds && highlightEmpty)
                }
                helperText={
                  raisedFundCheckbox == "No" ||
                  (!latestRounds && highlightEmpty)
                }
                required
              >
                <MenuItem value="Pre Seed">Pre Seed</MenuItem>
                <MenuItem value="Seed">Seed</MenuItem>
                <MenuItem value="Series A">Series A</MenuItem>
                <MenuItem value="Series B">Series B</MenuItem>
                <MenuItem value="Series C">Series C</MenuItem>
                <MenuItem value="Series C+">Series C+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="What was the last valuation? (in USD)"
              variant="outlined"
              value={lastValuation}
              placeholder="0"
              onChange={(e) => onFieldChange("lastValuation", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              fullWidth
              error={
                raisedFundCheckbox == "No" || (!lastValuation && highlightEmpty)
              }
              helperText={
                raisedFundCheckbox == "No" || (!lastValuation && highlightEmpty)
              }
              type="number"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Who led the round?"
              variant="outlined"
              value={whoLedTheRound}
              onChange={(e) => onFieldChange("whoLedTheRound", e.target.value)}
              fullWidth
              error={
                raisedFundCheckbox == "No" ||
                (!whoLedTheRound && highlightEmpty)
              }
              helperText={
                raisedFundCheckbox == "No" ||
                (!whoLedTheRound && highlightEmpty)
              }
              required
            />
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12}>
        <TextField
          label="how long you have been working on your startup? (in months)"
          variant="outlined"
          value={startUpDuration}
          type="number"
          onChange={(e) => onFieldChange("startUpDuration", e.target.value)}
          fullWidth
          placeholder="0"
          required
          error={!isStartUpDuration && highlightEmpty}
          helperText={!isStartUpDuration && highlightEmpty}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Website Link"
          variant="outlined"
          value={websiteLink}
          onChange={(e) => onFieldChange("websiteLink", e.target.value)}
          fullWidth
          required
          error={!isWebsiteLink && highlightEmpty}
          helperText={!isWebsiteLink && highlightEmpty}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Appstore Link"
          variant="outlined"
          value={appStoreLink}
          onChange={(e) => onFieldChange("appStoreLink", e.target.value)}
          fullWidth
          // required
          // error={!isAppStoreLink && highlightEmpty}
          // helperText={!isAppStoreLink && highlightEmpty}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Playstore Link"
          variant="outlined"
          value={playStoreLink}
          onChange={(e) => onFieldChange("playStoreLink", e.target.value)}
          fullWidth
          // required
          // error={!isPlayStoreLink && highlightEmpty}
          // helperText={!isPlayStoreLink && highlightEmpty}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Share demo & login details, etc"
          variant="outlined"
          value={demoLoginDetails}
          multiline
          rows={4}
          onChange={(e) => onFieldChange("demoLoginDetails", e.target.value)}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Step3 = ({
  firstName,
  lastName,
  linkdinProfile,
  email,
  phoneNumber,
  domain,
  lastEmployer,
  lastSchoolOrCollege,
  percentageStake,
  salaryDrawn,
  fullTimeOrPartTime,
  amountInvested,
  fundersArray,
  setFundersArray,
  funderObj,
  setFunderObj,
  onFieldChange,
  foundersNumber,
  formData,
  setFormData,
}) => {
  const [modelStatus, setModelStatus] = useState(false);

  const founderCount = Array.from(
    { length: foundersNumber },
    (_, index) => index + 1
  );

  const handleRemove = (indexToRemove) => {
    const updatedFundersArray = fundersArray.filter(
      (_, index) => index !== indexToRemove
    );
    setFundersArray(updatedFundersArray);
  };

  const isFieldValid = (item) => {
    return (
      item.firstName &&
      item.lastName &&
      item.phoneNumber &&
      item.email &&
      item.linkdinProfile &&
      item.domain &&
      item.lastEmployer &&
      item.lastSchoolOrCollege &&
      item.percentageStake &&
      item.salaryDrawn &&
      item.fullTimeOrPartTime &&
      item.amountInvested
    );
  };

  const [verifyButton, setVerifyButton] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [callFun, setCallFun] = useState(false);
  const userId = localStorage.getItem("userid");
  const founderArr = (foundersNumber) => {
    if (callFun) {
      const arrObj = [];
      for (let i = 0; i < foundersNumber; i++) {
        arrObj.push(funderObj);
      }
      setFundersArray(arrObj);
    }
  };

  const onSave = () => {
    // Save the founder data at the specified index in the array
    const isFilled = fundersArray.every((item) => {
      return (
        item.firstName &&
        item.lastName &&
        item.phoneNumber &&
        item.email &&
        item.linkdinProfile &&
        item.domain &&
        item.lastEmployer &&
        item.lastSchoolOrCollege &&
        item.percentageStake &&
        item.salaryDrawn &&
        item.fullTimeOrPartTime &&
        item.amountInvested
      );
    });

    if (isFilled) {
      // Save the founder data at the specified index in the array
      let updatedFundersArray = fundersArray;
      updatedFundersArray.push({
        // ... (existing code)
      });
      setFundersArray(updatedFundersArray);
    } else {
      // Display an error message or take appropriate action
      console.error("Please fill in all required fields before saving.");
    }
  };

  const handeChangeFounder = (name, index, value) => {
    const obj = JSON.parse(JSON.stringify(fundersArray[index]));
    obj[name] = value;
    fundersArray[index] = obj;
    setFundersArray((prevState) => {
      const newArray = [...prevState];
      newArray[index] = { ...newArray[index], ...obj };
      return newArray;
    });
  };

  const handleOnClickSendOtp = async (type, sendOn) => {
    try {
      const body =
        type == "email"
          ? {
              userId: userId,
              type: type,
              email: sendOn,
            }
          : {
              userId: userId,
              type: type,
              phoneNumber: sendOn,
            };

      const response = await axios.post(`${BASE_URL}/v1/user/sendotp`, body);

      if (response.data.success) {
        toast.success("Send OTP successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    founderArr(foundersNumber);
  }, [foundersNumber]);

  const onChangeFoundersData = (e) => {
    setFormData({ ...formData, foundersNumber: e.target.value });
    setCallFun(true);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="How many founders are there?"
          min={0}
          variant="outlined"
          value={foundersNumber}
          onChange={onChangeFoundersData}
          fullWidth
          type="number"
          placeholder="0"
          required
          error={!foundersNumber && highlightEmpty}
          helperText={!foundersNumber && highlightEmpty}
        />
      </Grid>
      <Grid item xs={12}>
        {fundersArray.map((item, index) => (
          <Accordion
            style={{
              backgroundColor: "#F3F0E7",
              border: "2px solid white",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Founder {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                ind
                xs={12}
                my="10px"
                style={{ textAlign: "left", fontSize: "10px" }}
              >
                <FormControlLabel
                  value={item?.linkdinProfile}
                  control={<Checkbox />}
                  label="Are you applying ?"
                  labelPlacement="start"
                  onChange={(e) => setVerifyButton(!verifyButton)}
                />
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid ind xs={6} my="10px">
                  <TextField
                    label="First Name"
                    variant="outlined"
                    value={item.firstName}
                    onChange={(e) =>
                      handeChangeFounder("firstName", index, e.target.value)
                    }
                    fullWidth
                    error={!item.firstName && highlightEmpty}
                    helperText={!item.firstName && highlightEmpty}
                    required
                  />
                </Grid>
                <Grid item xs={6} my="10px" ml={1}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    value={item?.lastName}
                    onChange={(e) =>
                      handeChangeFounder("lastName", index, e.target.value)
                    }
                    fullWidth
                    error={!item.lastName && highlightEmpty}
                    helperText={!item.lastName && highlightEmpty}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={6} my="10px">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {phoneVerified || item.isPhoneNumberVerified ? (
                      <VerifiedIcon sx={{ color: "green", mr: 1, my: 0.5 }} />
                    ) : (
                      ""
                    )}
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      value={item.phoneNumber}
                      onChange={(e) =>
                        handeChangeFounder("phoneNumber", index, e.target.value)
                      }
                      fullWidth
                      error={!item.phoneNumber && highlightEmpty}
                      helperText={!item.phoneNumber && highlightEmpty}
                      required
                      type="number"
                    />
                  </Box>

                  <Model
                    title={"Phone Number verification by OTP"}
                    isButtonHide={verifyButton}
                    type="phone"
                    value={item.phoneNumber}
                    phoneVerified={phoneVerified}
                    setPhoneVerified={setPhoneVerified}
                    style={{
                      border: phoneVerified ? "green" : "",
                      color: phoneVerified ? "green" : "",
                    }}
                    onClick={() =>
                      handleOnClickSendOtp("phone", item.phoneNumber)
                    }
                  />
                </Grid>
                <Grid item xs={6} my="10px" ml={1}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {emailVerified || item.isEmailVerified ? (
                      <VerifiedIcon sx={{ color: "green", mr: 1, my: 0.5 }} />
                    ) : (
                      ""
                    )}
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={item.email}
                      onChange={(e) =>
                        handeChangeFounder("email", index, e.target.value)
                      }
                      fullWidth
                      error={!item.email && highlightEmpty}
                      helperText={!item.email && highlightEmpty}
                      required
                    />{" "}
                  </Box>

                  <Model
                    title={"Email verification by OTP"}
                    isButtonHide={verifyButton}
                    type="email"
                    emailVerified={emailVerified}
                    setEmailVerified={setEmailVerified}
                    value={item.email}
                    onClick={() => handleOnClickSendOtp("email", item.email)}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Grid item xs={6} my="10px">
                  <TextField
                    label="Linkdin Profile"
                    variant="outlined"
                    value={item?.linkdinProfile}
                    onChange={(e) => {
                      handeChangeFounder(
                        "linkdinProfile",
                        index,
                        e.target.value
                      );
                      handeChangeFounder(
                        "isPhoneNumberVerified",
                        index,
                        phoneVerified
                      );
                      handeChangeFounder(
                        "isEmailVerified",
                        index,
                        emailVerified
                      );
                    }}
                    fullWidth
                    error={!item.linkdinProfile && highlightEmpty}
                    helperText={!item.linkdinProfile && highlightEmpty}
                    required
                  />
                </Grid>
                <Grid item xs={6} my="10px" ml={1}>
                  <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Domain *
                    </InputLabel>
                    <Select
                      label="Domain"
                      variant="outlined"
                      value={item.domain}
                      onChange={(e) =>
                        handeChangeFounder("domain", index, e.target.value)
                      }
                      fullWidth
                      error={!item.domain && highlightEmpty}
                      helperText={!item.domain && highlightEmpty}
                      required
                    >
                      <MenuItem value="Tech">Tech</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Sales">Sales</MenuItem>
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Finance">Finance</MenuItem>
                      <MenuItem value="HR">HR</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={6} my="10px">
                  <TextField
                    label="Where were you working earlier?"
                    variant="outlined"
                    value={item.lastEmployer}
                    onChange={(e) =>
                      handeChangeFounder("lastEmployer", index, e.target.value)
                    }
                    fullWidth
                    error={!item.lastEmployer && highlightEmpty}
                    helperText={!item.lastEmployer && highlightEmpty}
                    required
                  />
                </Grid>
                <Grid item xs={6} my="10px" ml={1}>
                  <TextField
                    label="Last school/college attended"
                    variant="outlined"
                    value={item.lastSchoolOrCollege}
                    onChange={(e) =>
                      handeChangeFounder(
                        "lastSchoolOrCollege",
                        index,
                        e.target.value
                      )
                    }
                    fullWidth
                    error={!item.lastSchoolOrCollege && highlightEmpty}
                    helperText={!item.lastSchoolOrCollege && highlightEmpty}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={6} my="10px">
                  <TextField
                    label="Percentage stake in startup"
                    variant="outlined"
                    value={item.percentageStake}
                    onChange={(e) =>
                      handeChangeFounder(
                        "percentageStake",
                        index,
                        e.target.value
                      )
                    }
                    fullWidth
                    error={!item.percentageStake && highlightEmpty}
                    helperText={!item.percentageStake && highlightEmpty}
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    type="number"
                  />
                </Grid>
                <Grid item xs={6} my="10px" ml={1}>
                  <TextField
                    label="Last Salary drawn"
                    variant="outlined"
                    value={item.salaryDrawn}
                    onChange={(e) =>
                      handeChangeFounder("salaryDrawn", index, e.target.value)
                    }
                    fullWidth
                    error={!item.salaryDrawn && highlightEmpty}
                    helperText={!item.salaryDrawn && highlightEmpty}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    type="number"
                    required
                  />
                </Grid>
              </Grid>
              {/* <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              > */}
              <Grid item xs={12} my="10px">
                <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Working full time or part time in this startup? *
                  </InputLabel>
                  <Select
                    label="Working full time or part time in this startup? (Yes/No)"
                    variant="outlined"
                    value={item.fullTimeOrPartTime}
                    onChange={(e) =>
                      handeChangeFounder(
                        "fullTimeOrPartTime",
                        index,
                        e.target.value
                      )
                    }
                    fullWidth
                    error={!item.fullTimeOrPartTime && highlightEmpty}
                    helperText={!item.fullTimeOrPartTime && highlightEmpty}
                    required
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} my="18px">
                <TextField
                  label="Amount invested in this startup so far? (USD)"
                  variant="outlined"
                  value={item.amountInvested}
                  onChange={(e) =>
                    handeChangeFounder("amountInvested", index, e.target.value)
                  }
                  fullWidth
                  error={!item.amountInvested && highlightEmpty}
                  helperText={!item.amountInvested && highlightEmpty}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  type="number"
                  required
                />
              </Grid>
              {/* </Grid> */}
              {/* <Grid item xs={12}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    fullWidth
                    style={{ borderColor: "black", color: "black" }}
                    onClick={onSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid> */}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

const Step4 = ({
  amountToRaise,
  reasonToUse,
  pitchDeck,
  amountInvestPersonally,
  continueWithoutFunds,
  reasonToSurvive,
  onFieldChange,
}) => {
  const isAmountToRaise = amountToRaise?.trim() !== "";
  const isReasonToUse = reasonToUse?.trim() !== "";
  const isPitchDeck = pitchDeck?.trim() !== "";
  const isAmountInvestPersonally = amountInvestPersonally?.trim() !== "";
  const isContinueWithoutFunds = continueWithoutFunds?.trim() !== "";
  const isReasonToSurvive = reasonToSurvive?.trim() !== "";

  const handleFileUpload = async (e) => {
    const body = new FormData();
    console.log(e.target.files[0],"step 4 file upload");
    body.append("file", e.target.files[0]);
    const response = await axios.post("${BASE_URL}/v1/user/upload/file", body, {
      headers: {
        "Content-Type": "application/json",
        accessToken: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
     console.log("rs",response)
    if (response.data.success) {
      onFieldChange("pitchDeck", response?.data?.data?.fileUrl);
    }

  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="How much are you looking to raise?"
          variant="outlined"
          value={amountToRaise}
          onChange={(e) => onFieldChange("amountToRaise", e.target.value)}
          fullWidth
          required
          error={!isAmountToRaise && highlightEmpty}
          helperText={!isAmountToRaise && highlightEmpty}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="What will you use it for?"
          variant="outlined"
          value={reasonToUse}
          multiline
          rows={4}
          onChange={(e) => onFieldChange("reasonToUse", e.target.value)}
          fullWidth
          required
          error={!isReasonToUse && highlightEmpty}
          helperText={!isReasonToUse && highlightEmpty}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel
          id="demo-simple-select-helper-label"
          style={{ textAlign: "left" }}
        >
          Upload your pitch deck *
        </InputLabel>
        <TextField
          // label="Upload your pitch deck"
          variant="outlined"
          // value={pitchDeck}
          name="file"
          onChange={(e) => handleFileUpload(e)}
          type="file"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            How much more can you invest personally if fundraising fails?
          </InputLabel>
          <Select
            label="How much more can you invest personally if fundraising fails?"
            variant="outlined"
            value={amountInvestPersonally}
            onChange={(e) =>
              onFieldChange("amountInvestPersonally", e.target.value)
            }
            fullWidth
            required
            error={!isAmountInvestPersonally && highlightEmpty}
            helperText={!isAmountInvestPersonally && highlightEmpty}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="5k+ USD">5k+ USD</MenuItem>
            <MenuItem value="10k+ USD">10k+ USD</MenuItem>
            <MenuItem value="25k+ USD">25k+ USD</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl sx={{ textAlign: "left", minWidth: "100%" }}>
          <InputLabel id="demo-simple-select-helper-label">
            Will you still continue to run if you dont raise funds? *
          </InputLabel>
          <Select
            label="Will you still continue to run if you dont raise funds?"
            variant="outlined"
            value={continueWithoutFunds}
            onChange={(e) =>
              onFieldChange("continueWithoutFunds", e.target.value)
            }
            fullWidth
            required
            error={!isContinueWithoutFunds && highlightEmpty}
            helperText={!isContinueWithoutFunds && highlightEmpty}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {continueWithoutFunds == "Yes" ? (
        <Grid item xs={12}>
          <TextField
            label="If yes, how will you survive?"
            variant="outlined"
            value={reasonToSurvive}
            multiline
            rows={4}
            onChange={(e) => onFieldChange("reasonToSurvive", e.target.value)}
            fullWidth
            required
            error={!isReasonToSurvive && highlightEmpty}
            helperText={!isReasonToSurvive && highlightEmpty}
          />
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default MultiStepForm;
