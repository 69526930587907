import { useEffect, useState } from "react";

import axios from "axios";
import moment from "moment";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Grid from "@mui/material/Grid";
import Header from "../Header/header";
import Sidebar from "../Sidebar/sidebar";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../../helper/getHeaders";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomeTable } from "../CustomeTable/customeTable";
import { BASE_URL } from "../../../config/config";

const useStyles = makeStyles((theme) =>

  createStyles({
    root: {
      "& .MuiDataGrid-renderingZone": {
        "& .MuiDataGrid-row": {
          "&:nth-child(2n)": { backgroundColor: "rgba(235, 235, 235, .7)" },
        },
      },
      "& .MuiDataGrid-main": {
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#ffd230 !important",
        },
      },
      "& .MuiDataGrid-footerContainer": {
        backgroundColor: "#ffd230 !important",
      },
    },
  })
);

const Cohort = () => {
  const [listData, setListData] = useState([]);
  const [totalCoh,setTotalCoh] = useState()
  const classes = useStyles();
  const navigate = useNavigate();
  const fetchData = async (query) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/getCohortDetails?skip=${query.skip}&pageSize=${query.pageSize}`,
        getHeaders()
      );
      const cohortData = response?.data?.data;
      const formattedData = cohortData.map((cohort, index) => ({
        id: index + 1,
        cohortName: cohort?.cohortName,
        cohortEndDate: cohort?.cohortEndDate
          ? moment(cohort?.cohortEndDate).format("DD-MM-YYYY")
          : cohort?.cohortEndDate,
        cohortApplicationStartDate: cohort?.cohortApplicationStartDate
          ? moment(cohort?.cohortApplicationStartDate).format("DD-MM-YYYY")
          : cohort?.cohortApplicationStartDate,
        cohortApplicationEndDate: cohort?.cohortApplicationEndDate
          ? moment(cohort?.cohortApplicationEndDate).format("DD-MM-YYYY")
          : cohort?.cohortApplicationEndDate,
        cohortStatus: cohort?.cohortStatus,
        cohortStartDate: cohort?.cohortStartDate
          ? moment(cohort?.cohortStartDate).format("DD-MM-YYYY")
          : cohort?.cohortStartDate,
        cohortId: cohort?._id,
      }));

      setListData(formattedData);
      setTotalCoh(response?.data?.total)

    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchData({skip:0,pageSize:10});
  }, []);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [fitSize, setFitSize] = useState(false);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "cohortName",
      headerName: "cohortName",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "cohortStartDate",
      headerName: "cohortStartDate",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "cohortEndDate",
      headerName: "cohortEndDate",
      flex: 1,
    },
    {
      field: "cohortApplicationStartDate",
      headerName: "cohortApplicationStartDate",
      flex: 1,
    },
    {
      field: "cohortApplicationEndDate",
      headerName: "cohortApplicationEndDate",
      flex: 1,
    },
    {
      field: "cohortStatus",
      headerName: "cohortStatus",
      flex: 1,
    },
  ];

  const filterField = [
    {
      key: "cohortName",
      value: "cohortName",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      key: "cohortStartDate",
      value: "cohortStartDate",
      headerAlign: "left",
      align: "left",
    },
    {
      key: "cohortEndDate",
      value: "cohortEndDate",
      flex: 1,
    },
    {
      key: "cohortApplicationStartDate",
      value: "cohortApplicationStartDate",
      flex: 1,
    },
    {
      key: "cohortApplicationEndDate",
      value: "cohortApplicationEndDate",
      flex: 1,
    },
    {
      key: "cohortStatus",
      value: "cohortStatus",
      flex: 1,
    },
  ];

  const filterDataApi = async (body) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/v1/user/filterCohorts`,
        {
          [body?.field]: body?.value,
        },
        getHeaders()
      );
      const cohortData = response?.data?.data;
      const formattedData = cohortData.map((cohort, index) => ({
        id: index + 1,
        cohortName: cohort?.cohortName,
        cohortEndDate: cohort?.cohortEndDate
          ? moment(cohort?.cohortEndDate).format("DD-MM-YYYY")
          : cohort?.cohortEndDate,
        cohortApplicationStartDate: cohort?.cohortApplicationStartDate
          ? moment(cohort?.cohortApplicationStartDate).format("DD-MM-YYYY")
          : cohort?.cohortApplicationStartDate,
        cohortApplicationEndDate: cohort?.cohortApplicationEndDate
          ? moment(cohort?.cohortApplicationEndDate).format("DD-MM-YYYY")
          : cohort?.cohortApplicationEndDate,
        cohortStatus: cohort?.cohortStatus,
        cohortStartDate: cohort?.cohortStartDate
          ? moment(cohort?.cohortStartDate).format("DD-MM-YYYY")
          : cohort?.cohortStartDate,
        cohortId: cohort?._id,
      }));

      setListData(formattedData);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Sidebar fitSize={setFitSize} />
        <Grid xs={9} md={fitSize ? 11 : 9}>
          <Box m="20px">
            <Header title="Cohort" subtitle="Managing the Cohort" />
            <Button
              variant="outlined"
              startIcon={<AddBoxIcon />}
              onClick={() => {
                navigate("/admin/cohort/form");
              }}
              style={{ color: "black", borderColor: "black" }}
            >
              Add Cohort
            </Button>
            <Box
              m="10px 0 0 0"
              height="80vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              <CustomeTable
                data={listData}
                filterField={filterField}
                columns={columns}
                apifuncation={filterDataApi}
                navigate={navigate}
                fetchData={fetchData}
                total={totalCoh}
                type="cohort"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cohort;
