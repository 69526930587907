import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent, {
  timelineContentClasses,
} from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import axios from "axios";
import moment from "moment";
import { BASE_URL } from "../../../config/config";
import ReactGA from "react-ga4";

ReactGA.initialize("G-E7HK2T1D8X");

export default function RightAlignedTimeline({ cohortId }) {
  const [applicationData, setApplicationData] = useState();
  const userId = localStorage.getItem("userid");
  let [timelineObj,setTimelineObj] =useState([
    {
      date: null,
      status: "applicationNotStarted",
      connectorHide: false,
      display: "Application Not Started",
      isHighLight: false,
    },
    {
      date: null,
      status: "applicationInProgress",
      connectorHide: false,
      display: " Application In Progress",
      isHighLight: false,
    },
    {
      date: null,
      status: "applicationSubmitted",
      connectorHide: false,
      display: "Application Submitted ",
      isHighLight: false,
    },
    {
      date: null,
      status: "applicaitonInReview",
      connectorHide: false,
      display: "Application In Review",
      isHighLight: false,
    },
    {
      date: null,
      status: "shortlistedFprAssessmen",
      connectorHide: false,
      display: "Shortlisted For Assessment",
      isHighLight: false,
    },
    {
      date: null,
      status: "shortlistedForTeleCall",
      connectorHide: false,
      display: "Shortlisted For Tele Call With Investors",
      isHighLight: false,
    },
    {
      date: null,
      status: "ddInProcess",
      connectorHide: false,
      display: "DD In Process",
      isHighLight: false,
    },
    {
      date: null,
      status:"",
      connectorHide: true,
      display: "Selected",
      isHighLight: false,
    },
  ]) ;
  const fetchApplicationData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/application/status/track?cohortId=${cohortId}&userId=${userId}`
      );

      if (response.data.success) {
        const data = response?.data?.data
        setApplicationData(data);
        const timelineData =  timelineObj?.map(item =>{
          data?.applicationProgressStatus?.map(obj=>{
            console.log("This is object status", obj?.status )
            console.log("This is item status", item?.status)
            if(item?.status==obj.status){
              console.log("I am inside the if statement", item.status, obj.status)
              item.date = obj.time
              item.isHighLight = true
            }
          })
          return item
         })

         setTimelineObj(timelineData)
         reverseHighLight(timelineData)
      }
    } catch (error) {
      console.log(error);
    }
  };


 const reverseHighLight = (timelineData)=>{
     const len = timelineData.length - 1
     let isHighLight = false
     timelineData?.map((item,index)=>{
        if(timelineData[len - index].isHighLight) {
          isHighLight = true
        }
        if(isHighLight){
          timelineData[len - index].isHighLight = true
        }
     })
     setTimelineObj(timelineData)
 }

  useEffect(() => {
    fetchApplicationData();
  }, [cohortId]);

  return (
    <div
      style={{
        width: "90%",
        // backgroundColor:'white',
        borderRadius: "10px",
        margin: "25px",
      }}
    >
      <Timeline>
        {timelineObj.map((item, index) => {

          return (
            <TimelineItem>
              <TimelineOppositeContent color="black">
                {/* {item?.date ? moment(item?.time).format("DD MMM YYYY") : ""} */}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  style={{ borderColor: item?.isHighLight ? "#ffd230" : "grey" }}
                />
                {!item.connectorHide ? (
                  <TimelineConnector style={{ backgroundColor: item?.isHighLight ? "#ffd230" : "grey" }} />
                ) : (
                  ""
                )}
              </TimelineSeparator>
              <TimelineContent color="black">{item.display}</TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </div>
  );
}
