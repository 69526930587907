import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../config/config";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-W6NT2KBT",
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize("G-E7HK2T1D8X");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 568 ? 400 : 500,
  bgcolor: "background.paper",
  border: "0px solid #ffd230",
  borderRadius: "20px",
  boxShadow: 24,
  p: window.innerWidth < 568 ? 1 : 4,
  textAlign: "center",
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(props.status);
  const handleOpen = () => {
    setOpen(true);
    props.onClick();
  };
  const handleClose = () => {
    setOpen(false);
    setOtp("------");
  };
  const [otp, setOtp] = useState("------");
  const [progress, setProgress] = useState(60);
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const { phoneVerified, setPhoneVerified, emailVerified, setEmailVerified } =
    props;

  const userId = localStorage.getItem("userid");

  const handleOnClickVerifyOtp = async (type, sendOn) => {
    try {
      const body =
        type == "email"
          ? {
              userId: userId,
              type: type,
              email: sendOn,
              otp: otp,
            }
          : {
              userId: userId,
              type: type,
              phoneNumber: sendOn,
              otp: otp,
            };

      const response = await axios.post(`${BASE_URL}/v1/user/verifyotp`, body);

      if (response.data.success) {
        toast.success("Verify OTP successfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (props.type == "email") {
          setEmailVerified(true);
        } else {
          setPhoneVerified(true);
        }
        handleClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress > 0) {
        setProgress((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [progress]);

  return (
    <div>
      {!props?.isButtonHide ? (
        ""
      ) : props.type == "email" && emailVerified ? (
        ""
      ) : props.type == "phone" && phoneVerified ? (
        ""
      ) : (
        <Button
          onClick={handleOpen}
          style={{
            color: "black",
            marginLeft: "10px",
            fontSize: "14px",
            marginTop: "10px",
            marginBottom: "10px",
            textTransform: "capitalize",
            backgroundColor: "#ffd230",
          }}
        >
          Verify
        </Button>
      )}
      <Grid container spacing={2}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {props.title}
            </Typography>
            <Box style={{ margin: "20px" }}>
              <MuiOtpInput value={otp} length={6} onChange={handleChange} />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              <Button
                onClick={() => {
                  handleOnClickVerifyOtp(props.type, props.value);
                }}
                style={{
                  backgroundColor: "#ffd230",
                  color: "black",
                  marginLeft: "10px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
              >
                Verify OTP
              </Button>
              <Box style={{ marginLeft: "20px" }}>
                <Button
                  onClick={handleOpen}
                  style={{
                    backgroundColor: "#ffd230",
                    color: "black",
                    marginLeft: "10px",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  Resend OTP
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </div>
  );
}
