import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Paper,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import Filter from "../Filter/filter";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 400,
  },
  stickyHeader: {
    position: "sticky !importanrt",
    top: 0,
    background: "white",
    zIndex: 1,
  },
});

export const CustomeTable = ({
  data,
  columns,
  navigate,
  type,
  apifuncation,
  fetchData,
  filterField,
  total,
}) => {
  const [filter, setFilter] = useState("");
  const [field, setField] = useState(filterField);
  const classes = useStyles();

  const filteredData = data;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData({ skip: newPage * rowsPerPage, pageSize: rowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fetchData({
      skip: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  return (
    <div>
      <Filter
        filter={filter}
        setFilter={setFilter}
        field={field}
        apifuncation={apifuncation}
      />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead
            style={{
              position: "sticky !importanrt",
              top: 0,
              background: "white",
              zIndex: 1,
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
              <TableCell key={"action"}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.field}>{row[column.field]}</TableCell>
                ))}
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  style={{
                    color: "black",
                    borderColor: "black",
                    margin: "5px",
                  }}
                  onClick={() => {
                    if (type == "cohort") {
                      navigate(
                        `/admin/cohort/update?cohortId=${row?.cohortId}`
                      );
                    } else {
                      navigate(
                        `/admin/application/update?applicationId=${row?.appId}`
                      );
                    }
                  }}
                >
                  Edit
                </Button>
              </TableRow>
            ))}

            {data.length == 0 && type != "cohort" ? (
              <TableCell key="not found">
                Applications data not found!
              </TableCell>
            ) : data.length == 0 && type == "cohort" ? (
              <TableCell key="not found1">Cohort data not found!</TableCell>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
