import React, { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W6NT2KBT'
}
 
TagManager.initialize(tagManagerArgs)

ReactGA.initialize("G-E7HK2T1D8X");

const Timer = ({ targetDate }) => {
  const calculateRemainingTime = () => {
    if (!targetDate) {
      return 0; // If no target date is provided, default to zero
    }

    const now = new Date();
    const difference = new Date(targetDate) - now;
    return Math.max(0, Math.floor(difference / 1000)); // Ensure the timer doesn't go negative
  };

  const [durationInSeconds, setDurationInSeconds] = useState(calculateRemainingTime());

  const formatTime = (duration) => {
    const days = Math.floor(duration / (24 * 60 * 60));
    const hours = Math.floor((duration % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((duration % (60 * 60)) / 60);
    const seconds = duration % 60;

    return `${days}d:${hours < 10 ? '0' : ''}${hours}h:${minutes < 10 ? '0' : ''}${minutes}m:${seconds < 10 ? '0' : ''}${seconds}s`;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setDurationInSeconds(calculateRemainingTime());
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [targetDate]);

  return (
      <span>{formatTime(durationInSeconds)}</span>
  );
};

export default Timer;
