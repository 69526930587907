import "./adminLogin.css";
import { FaLinkedinIn } from "react-icons/fa6";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getHeaders } from "../../helper/getHeaders";
import Loader from "../Loader/loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../config/config";

export const AdminLogin = (props) => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useState({
    email: null,
    password: null,
  });
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAuthData({ ...authData, [name]: value });
  };

  const handleOnClick = async () => {
    setLoader(true);
    await axios
      .post(`${BASE_URL}/v1/user/loginUser`, authData)
      .then((response) => {
        if (response.data.success) {
          setLoader(false);
          toast.success("Login successfully", {
            position: toast.POSITION.TOP_RIGHT,
          })
          localStorage.setItem("userType",response.data.data.userType || "admin")
          localStorage.setItem("id",response.data.data._id)
          props.onLogin(response.data.data.userType || "admin")
          navigate("/admin/dashboard");
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(
          error.message.toLowerCase() == "Network Error".toLowerCase()
            ? "Login Failed"
            : error.message,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        console.log(error.message);
      });
  };

  const validateEmail = (email) => {
    // Basic email format validation
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!emailRegex.test(email)) {
      return "Invalid Email";
    } else {
      return "";
    }
  };

  return (
    <>
      <div className="bodyStyle">
        <div id="container">
          <div className="top">
          <img src="https://sunday-venture.s3.ap-south-1.amazonaws.com/images1701502958920.png" alt="logo" className="image" style={{margin:'-20px 20%'}} />
          </div>
          <form>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={authData?.email}
              onChange={handleChange}
              className="input"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={authData?.password}
              onChange={handleChange}
              className="input"
              required
            />
            {loader ? (
              <Loader />
            ) : authData.email && authData.password ? (
              <button name="login" type="button" onClick={handleOnClick}>
                Login
              </button>
            ) : (
              <button name="login" type="button" disabled className="disabled">
                Login
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
