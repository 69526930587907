import React, { useState,useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from "axios";
import { Alert } from "@mui/material";
import "./form.css";
import { getHeaders } from "../../helper/getHeaders";
import Sidebar from "../Sidebar/sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/loader";
import Header from "../Header/header";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import moment from 'moment'
import { BASE_URL } from "../../../config/config";

const EditCohort = (props) => {
  const [cohortName, setCohortName] = useState("");
  const [cohortStartDate, setCohortStartDate] = useState("");
  const [cohortEndDate, setCohortEndDate] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cohortId = queryParams.get("cohortId")
  const [cohortApplicationStartDate, setCohortApplicationStartDate] =
    useState("");
  const [loader, setLoader] = useState(false);
  const [cohortApplicationEndDate, setCohortApplicationEndDate] = useState("");
  const [cohortStatus, setCohortStatus] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fitSize, setFitSize] = useState();
  const [cohortData,setCohortData] = useState()

  const navigate = useNavigate();

  // const validateForm = () => {
  //   const errors = {};

  //   if (!cohortName.trim()) {
  //     errors.cohortName = "Cohort Name is required";
  //   }

  //   if (!cohortStartDate) {
  //     errors.cohortStartDate = "Cohort Start Date is required";
  //   }

  //   if (!cohortEndDate) {
  //     errors.cohortEndDate = "Cohort End Date is required";
  //   }

  //   if (!cohortApplicationStartDate) {
  //     errors.cohortApplicationStartDate = "Application Start Date is required";
  //   }

  //   if (!cohortApplicationEndDate) {
  //     errors.cohortApplicationEndDate = "Application End Date is required";
  //   }

  //   if (!cohortStatus) {
  //     errors.cohortStatus = "Status is required";
  //   }

  //   setValidationErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/getCohortDetails?cohortId=${cohortId}`,
        getHeaders()
      );

      if(response.data.success){
        const cohort =  response?.data?.data
        setCohortName(cohort?.cohortName)
        setCohortStartDate(cohort?.cohortStartDate)
        setCohortEndDate(cohort?.cohortEndDate)
        setCohortApplicationStartDate(cohort?.cohortApplicationStartDate)
        setCohortApplicationEndDate(cohort?.cohortApplicationEndDate)
        setCohortStatus(cohort?.cohortStatus)
      }

    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const updateFromDetails = async () => {
    // if (!validateForm()) {
    //   return;
    // }
    setLoader(true);

    try {
      const response = await axios.put(
        `${BASE_URL}/v1/user/updateCohortDetails`,
        {
          cohortId: cohortId,
          cohortName:cohortName,
          cohortStartDate:cohortStartDate,
          cohortEndDate:cohortEndDate,
          cohortApplicationStartDate:cohortApplicationStartDate,
          cohortApplicationEndDate:cohortApplicationEndDate,
          cohortStatus:cohortStatus,
        },
        // getHeaders()
      );

      if (response?.data?.success === true) {
        // setSuccessMessage("Successfully Added the details");
        setErrorMessage(null);
        toast.success("Successfully update the details", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
        navigate("/admin/cohorts");
      } else {
        // setErrorMessage("Not able to add the details");
        // setSuccessMessage(null);
        setLoader(false);
        toast.success("Not able to add the details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      //   setErrorMessage("Not able to add the details");
      setLoader(false);
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      //   setSuccessMessage(null);
    }
  };

  const handleInputChange = (field, value) => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: null }));

    switch (field) {
      case "cohortName":
        setCohortName(value);
        break;
      case "cohortStartDate":
        setCohortStartDate(value);
        break;
      case "cohortEndDate":
        setCohortEndDate(value);
        break;
      case "cohortApplicationStartDate":
        setCohortApplicationStartDate(value);
        break;
      case "cohortApplicationEndDate":
        setCohortApplicationEndDate(value);
        break;
      case "cohortStatus":
        setCohortStatus(value);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar fitSize={setFitSize} />
      <main className="demo-page-content" style={{ margin: "auto auto" }}>
        <section>
          <KeyboardBackspaceIcon
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <h1>{props.header || "Update Cohort"}</h1>
          <div className="nice-form-group">
            <label>Cohort Name *</label>
            <input
              type="text"
              placeholder="Enter Cohort Name"
              onChange={(e) => handleInputChange("cohortName", e.target.value)}
              // onBlur={validateForm}
              value={cohortName}
              
            />
            {validationErrors.cohortName && (
              <>
                <br></br>
                <br></br>
                <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                  {validationErrors.cohortName}
                </span>
              </>
            )}
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="nice-form-group">
              <label>Cohort Start Date *</label>
              <input
                type="date"
                value={moment(cohortStartDate).format("yyyy-MM-DD")}
                onChange={(e) =>
                  handleInputChange("cohortStartDate", e.target.value)
                }
                // onBlur={validateForm}
                style={{ marginRight: "20px" }}
              />
              {validationErrors.cohortStartDate && (
                <>
                  <br></br>
                  <br></br>
                  <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                    {validationErrors.cohortStartDate}
                  </span>
                </>
              )}
            </div>

            <div className="nice-form-group">
              <label>Cohort End Date *</label>
              <input
                type="date"
                value={moment(cohortEndDate).format("yyyy-MM-DD")}
                onChange={(e) =>
                  handleInputChange("cohortEndDate", e.target.value)
                }
                // onBlur={validateForm}
              />
              {validationErrors.cohortEndDate && (
                <>
                  <br></br>
                  <br></br>
                  <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                    {validationErrors.cohortEndDate}
                  </span>
                </>
              )}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="nice-form-group">
              <label>Cohort Application Start Date *</label>
              <input
                type="date"
                value={moment(cohortApplicationStartDate).format("yyyy-MM-DD")}
                onChange={(e) =>
                  handleInputChange(
                    "cohortApplicationStartDate",
                    e.target.value
                  )
                }
                // onBlur={validateForm}
              />
              {validationErrors.cohortApplicationStartDate && (
                <>
                  <br></br>
                  <br></br>
                  <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                    {validationErrors.cohortApplicationStartDate}
                  </span>
                </>
              )}
            </div>

            <div className="nice-form-group">
              <label>Cohort Application End Date *</label>
              <input
                type="date"
                value={moment(cohortApplicationEndDate).format("yyyy-MM-DD")}
                onChange={(e) =>
                  handleInputChange("cohortApplicationEndDate", e.target.value)
                }
                // onBlur={validateForm}
              />
              {validationErrors.cohortApplicationEndDate && (
                <>
                  <br></br>
                  <br></br>
                  <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                    {validationErrors.cohortApplicationEndDate}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="nice-form-group">
            <label>Status *</label>
            <select
              value={cohortStatus}
              onChange={(e) =>
                handleInputChange("cohortStatus", e.target.value)
              }
              // onBlur={validateForm}
            >
              <option value="">Select the Status</option>
              <option value="scheduled">SCHEDULED</option>
              <option value="active">ACTIVE</option>
              <option value="completed">COMPLETED</option>
              <option value="dropped">DROPPED</option>
            </select>
            {validationErrors.cohortStatus && (
              <>
                <br></br>
                <br></br>
                <span style={{ color: "red", marginTop: "10", width: "50%" }}>
                  {validationErrors.cohortStatus}
                </span>
              </>
            )}
          </div>

          <details>
            <summary>
              {loader ? (
                <Loader />
              ) : (
                <div
                  className="toggle-code"
                  style={{ backgroundColor: "#ffd230" }}
                  onClick={updateFromDetails}
                >
                  update
                </div>
              )}
            </summary>
          </details>

          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </section>
      </main>
    </div>
  );
};

export default EditCohort;
