import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import RightAlignedTimeline from "../Timeline/timeline";
import "./application.css";
import { Header } from "../Header/header";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import moment from "moment";
import Timer from "./timer";
import { TypeAnimation } from "react-type-animation";
import { BASE_URL } from "../../../config/config";
import Loader from "../../Admin/Loader/loader";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W6NT2KBT'
}
 
TagManager.initialize(tagManagerArgs)

ReactGA.initialize("G-E7HK2T1D8X");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function UserApplication({ user }) {
  const navigate = useNavigate();
  const innerWidth = window.innerWidth;
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const userId = localStorage.getItem("userid");
  const [appData, setAppData] = useState();
  const [appStatus, setAppStatus] = useState();
  let status = null;
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}/v1/user/getlastApplicationAndCohortData`
      );
      if (response.data.success) {
        setPageData(response.data.data);
        if (response?.data?.data?.activeCohort?._id) {
          fetchAppData(response?.data?.data?.activeCohort?._id);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAppData = async (cohortId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/getApplicationDetailsbyCohort?cohortId=${cohortId}&userId=${userId}`
      );

      const appData = response.data.data;
      status = appData.applicationStatus;
      setAppData(appData);
      setAppStatus(appData.applicationStatus);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  let text = "Good Morning";
  const currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const eveningStart = moment().format("YYYY-MM-DD 18:00:00");
  const eveningEnd = moment().format("YYYY-MM-DD 04:00:00");
  const afternoonStart = moment().format("YYYY-MM-DD 12:00:00");
  const afternoonEnd = moment().format("YYYY-MM-DD 18:00:00");
  if (currentDateTime > eveningStart && currentDateTime < eveningEnd) {
    text = "Good Evening";
  } else if (
    currentDateTime > afternoonStart &&
    currentDateTime < afternoonEnd
  ) {
    text = "Good Afternoon";
  }

  console.log(text);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            backgroundColor: "#f8f9f0",
          }}
        >
          <Header />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div
                className="content"
                style={{
                  margin: "20px 10%",
                }}
              >
                <div className="conetnt-box">
                  <p>
                    {text}, {user?.displayName}
                  </p>
                  <h1
                    style={{
                      color: "black",
                      fontSize: "30px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      letterSpacing: "2px",
                    }}
                    className="responsive-text"
                  >
                    {appStatus == "inprogress"
                      ? "Complete your application"
                      : appStatus == "in_review" ||
                        appStatus == "applied" ||
                        appStatus == "in_dd" ||
                        appStatus == "shortlisted" ||
                        appStatus == "shortlisted_for_telecall"
                      ? "Your application is in review"
                      : appStatus == "selected"
                      ? "Your Application is Selected"
                      : appStatus == "rejected" ?"Your Application is Rejected":!appStatus
                      ? "Start your application"
                      : "Start your application"}
                  </h1>
                  {pageData?.activeCohort ? (
                    <p
                      style={{
                        color: "red",
                        fontSize: "18px",
                        letterSpacing: "1.5px",
                      }}
                      className="responsive-text"
                    >
                      {moment(
                        pageData?.activeCohort?.cohortApplicationEndDate
                      ).format("MMM D")}{" "}
                      cohort application closes in{" "}
                      <Timer
                        targetDate={
                          pageData?.activeCohort?.cohortApplicationEndDate
                        }
                      />
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "red",
                        fontSize: "18px",
                        letterSpacing: "1.5px",
                      }}
                      className="responsive-text"
                    >
                      No active cohort!
                    </p>
                  )}
                </div>
                <div
                  className="button"
                  style={{
                    marginTop: "30%",
                  }}
                >
                  <Stack spacing={20} direction="row">
                    {!appData ||
                    !appData?.applicationStatus ||
                    appData?.applicationStatus == "inprogress" ||
                    appData?.applicationStatus == "not_apply" ? (
                      <Button
                        style={{
                          width: innerWidth < 568 ? "100%" : "50%",
                          fontSize: innerWidth < 568 ? "18px" : "14px",
                          padding: "10px",
                          color: "#0d0d0d",
                          backgroundColor: "#ffd230",
                          textTransform: "capitalize",
                        }}
                        onClick={() =>
                          navigate(
                            `/application/form?cohortId=${pageData?.activeCohort?._id}`
                          )
                        }
                      >
                        {appStatus == "inprogress"
                          ? "Complete Your Application"
                          : "Apply Now"}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: innerWidth < 568 ? "100%" : "50%",
                          fontSize: innerWidth < 568 ? "18px" : "14px",
                          padding: "10px",
                          color: "#0d0d0d",
                          backgroundColor: "grey",
                          textTransform: "capitalize",
                          cursor: "not-allowed",
                        }}
                      >
                        Apply Now
                      </Button>
                    )}
                  </Stack>
                </div>
                {window.innerWidth > 768 ? (
                  <div
                    style={{
                      margin: "30px 0px",
                    }}
                    className="responsive-text"
                  >
                    <h1
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Past Application
                    </h1>
                    <ul
                      style={{
                        padding: "10px",
                        listStyleType: "disc",
                      }}
                    >
                      {pageData?.lastApplication ? (
                        <li>
                          {moment(
                            pageData?.lastApplication?.cohortEndDate
                          ).format("MMM D")}{" "}
                          Cohort -{" "}
                          {pageData?.lastApplication?.applicationStatus}
                        </li>
                      ) : (
                        <li>No Past Application</li>
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                padding: "0px",
                marginTop: "60px",
              }}
            >
              <Box>
                <div
                  style={{
                    marginRight: window.innerWidth < 450 ? "25%" : "",
                  }}
                >
                  <RightAlignedTimeline
                    cohortId={pageData?.activeCohort?._id}
                  />
                </div>
                {window.innerWidth < 768 ? (
                  <div
                    style={{
                      margin: "0% 20%",
                    }}
                    className="responsive-text"
                  >
                    <h1
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                    >
                      Past Application
                    </h1>
                    <ul
                      style={{
                        padding: "10px",
                        listStyleType: "disc",
                      }}
                    >
                      {pageData?.lastApplication ? (
                        <li>
                          {moment(
                            pageData?.lastApplication?.cohortEndDate
                          ).format("MMM D")}{" "}
                          Cohort -{" "}
                          {pageData?.lastApplication?.applicationStatus}
                        </li>
                      ) : (
                        <li>No Past Application</li>
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </Box>
              <center>
                <div>
                  {window.innerWidth <= 568 ? (
                    <Button
                      color="primary"
                      onClick={() => window.open(`${BASE_URL}/logout`, "_self")}
                      style={{
                        backgroundColor: "transparent",
                        textTransform: "capitalize",
                        color: "black",
                        margin: "20px 50px",
                        width: "3%",
                        fontSize: "14px",
                      }}
                    >
                      Logout
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </center>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
