import React, { useState,useEffect } from 'react';
import { Route, Routes, Navigate , useLocation ,useNavigate, Link} from 'react-router-dom';
import { AdminLogin } from './Components/Admin/Auth/adminLogin';
import { AdminSignup } from './Components/Admin/Auth/adminSignup';
import { Home } from './Components/Admin/Home/home';
import Cohort from './Components/Admin/Cohort/cohort';
import Form from './Components/Admin/Cohort/form';
import Applications from './Components/Admin/Applications/application';
import ApplicationForm from './Components/Admin/Applications/applicationForm';
import { CustomeTable } from './Components/Admin/CustomeTable/customeTable';
import UserApplication from './Components/User/Application/application';
import UserLogin from './Components/User/Auth/userLogin';
import MultiStepForm from './Components/User/Application/multipartForm';
import EditCohort from './Components/Admin/Cohort/editCohort'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from  'axios'
import { BASE_URL } from "./config/config";

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-W6NT2KBT'
}
 
TagManager.initialize(tagManagerArgs)

const Redirect = ()=>{
  window.location.href = "/admin"
  return <>
   
  </>
}

const Redirect2 = ()=>{
  window.location.href = "/admin/dashboard"
  return <>
   
  </>
}

function App() {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [userData,setUserData] = useState()
  const [isAdminLoggedIn,setIsAdminLoggedIn] = useState(false)
  const [type,setType] = useState(null)
  const navigate = useNavigate() 
  const location = useLocation
   const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role");
  const adminId = localStorage.getItem("id")
  const typeUser = localStorage.getItem("userType")

  if(typeUser=="admin" && type==null) setType(typeUser)

  const [userType, setUserType] = useState(role);
   // 'admin', 'user', or any other role
  const handleLogin = (userType) => {
    setLoggedIn(true);
    // Set user type based on your authentication logic
    setUserType(userType); // Replace with the actual user type
  };

  const handleAdminLogin = (type)=>{
     setIsAdminLoggedIn(true)
     setType(type)
  }

  const handleLogout = () => {
    setLoggedIn(false);
    setUserType('');
    if(localStorage.getItem("userid")){
      // toast.success("Logout successfull", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }  
    localStorage.clear()
    !role ?? navigate("/")
  };

  const fetchUser = async ()=>{
       try {

      const response = await axios.get(`${BASE_URL}/login/success`,{ withCredentials: true });
      if (response.data) {
        setUserData(response.data.data)
        if(!localStorage.getItem("userid")){
          // toast.success("Login successfull", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
        localStorage.setItem("userid",response.data.data._id)
        handleLogin("user")
      }

    }catch (error) {
      console.log(error)
      if(typeUser!='admin') handleLogout()
    }
  }

  const fetchAdminUser = async ()=>{
    try {

      const response = await axios.get(`${BASE_URL}/v1/user/details`,{
        userId:adminId
      });

      if (response.data.success) {
        const data = response.data.data
        handleAdminLogin(data.userType || "admin")
        isAdminLoggedIn(true)
      }

    }catch (error) {
      console.log(error)
      // handleLogout()
    }
  }

  useEffect(()=>{
       if((userType=="user" || !userType) && !role)  fetchUser() 
       if(adminId) fetchAdminUser()
  },[])

  return (
    <>
      <Routes>
        {/* <Route
          path="/admin"
          element={(isLoggedIn && userType=='admin') || !userType ? <Navigate to="/admin/dashboard" /> : <AdminLogin onLogin={handleLogin} />}
        /> */}
        <Route
          path="/"
          exact
          element={isLoggedIn && userType=='user' ? <Navigate to="/application" /> : <UserLogin onLogin={handleLogin} />}
        />
        {type === 'admin' ? (<>
          <Route exact path="/admin" element={<Redirect2 />} />
          <Route exact path="/admin/dashboard" element={<Home />} />
          <Route exact path="/admin/cohorts" element={<Cohort />} />
          <Route exact path="/admin/applications" element={<Applications />} />
          <Route exact path="/admin/cohort/form" element={<Form />} />
          <Route exact path="/admin/test" element={<CustomeTable />} />
          <Route exact path="/admin/application/update" element={<ApplicationForm />} />
          <Route exact path="/admin/cohort/update" element={<EditCohort />} />
        </>) : (
          <>
          <Route exact path="/admin" element={<AdminLogin onLogin={handleAdminLogin}/>} />
          <Route exact path="/admin/dashboard" element={<Redirect />} />
          <Route exact path="/admin/cohorts" element={<Redirect />} />
          <Route exact path="/admin/applications" element={<Redirect />} />
          <Route exact path="/admin/cohort/form" element={<Redirect />} />
          <Route exact path="/admin/test" element={<Redirect />} />
          <Route exact path="/admin/application/update" element={<Redirect />} />
          <Route exact path="/admin/cohort/update" element={<Redirect />} />
          </>
        )
    }
        {isLoggedIn && userType === 'user' ? (
             <>
             <Route exact path="/application" element={<UserApplication user={userData}/>} />
             <Route exact path="/application/form" element={<MultiStepForm user={userData} />} />
             </>
          ) : userType==='user' ? <Route exact path="*" element={<Navigate to={userType === 'user' ? "/" : "/admin"}/>}/> : ""}  
      </Routes>
    </>
  );
}

export default App;
