import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";

export default function CustomizedTimeline({ data }) {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.inprogressApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Application In Progress
          </Typography>
          {/* <Typography>Because you need strength</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        >
          {/* 10:00 am */}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.submitedApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Application Submitted
          </Typography>
          {/* <Typography>Because it&apos;s awesome!</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.inReviewApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Application In Review
          </Typography>
          {/* <Typography>Because you need rest</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.shortlistApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Shortlisted For Assessmen
          </Typography>
          {/* <Typography>Because this is the life you love!</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.shortlistForTellCallApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Shortlisted For Tele Call With Investors
          </Typography>
          {/* <Typography>Because you need rest</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.ddInprogressApplication}
            </Typography>
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            DD In Process
          </Typography>
          {/* <Typography>Because this is the life you love!</Typography> */}
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot style={{ padding: "10px" }}>
            <Typography
              variant="p"
              style={{ fontSize: "12px", color: "black" }}
            >
              {data?.selected + data?.rejected}
            </Typography>
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="p" component="span" style={{ fontSize: "14px" }}>
            Selected / Rejected
          </Typography>
          {/* <Typography>Because you need rest</Typography> */}
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
