import Sidebar from "../Sidebar/sidebar";
import "./home.css";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Header from "../Header/header";
import CheckboxListSecondary from "./itemList";
import { useEffect, useState } from "react";
import RightAlignedTimeline from "../Timelines/timelines";
import OutlinedCard from "../Card/card";
import axios from "axios";
import { BASE_URL } from "../../../config/config";

export const Home = () => {
  const [fitSize, setFitSize] = useState();
  const [dashboardData, setDashboardData] = useState();

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/v1/user/dashboard`
      );
      if (response.data.success) {
        setDashboardData(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Sidebar fitSize={setFitSize} />
          <Grid xs={8} md={8}>
            <Box m="50px">
              <Header title="" subtitle="" />
              <Grid
                container
                rowSpacing={1}
                spacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid xs={6} style={{ textAlign: "center" }}>
                  <OutlinedCard title={"Total Applications"} count={dashboardData?.totalApplication} />
                </Grid>
                <Grid xs={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <RightAlignedTimeline 
                     data={dashboardData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
