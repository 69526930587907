import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const colors = tokens(theme.palette.mode);
  return (
    <Box mb="30px">
      {/* <Button
        variant="outlined"
        style={{
        //   width: "50%",
        //   padding: "10px",
          border:'0px'
          borderColor: "#0d0d0d",
          color: "#0d0d0d",
        }}
      > */}
      <KeyboardBackspaceIcon style={{ color: "black", cursor: "pointer" }} onClick={()=> navigate(-1)}/>
      {/* </Button> */}
      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bold",
          padding: "20px 0px 0px 0px",
          letterSpacing: "1.5px",
        }}
      >
        {title}
      </h1>
      {/* <p>{subtitle}</p> */}
    </Box>
  );
};

export default Header;
